import { FC } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { selectRetailerLanguages } from '@lib/core/retailers/selectors/retailer';
import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import LanguageSwitcherComponent from '@components/pmi/src/molecules/LanguageSwitcher';
import { checkCurrentPrimaryColor } from '@components/pmi/src/utils';

interface Props {
  handleClose: () => void;
  supportedLanguages: {
    code: string;
    language: string;
  }[];
}

const HealthWarningPrePage: FC<Props> = ({ handleClose, supportedLanguages }) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const currentPrimaryColor = checkCurrentPrimaryColor();
  const warning = (
    <LocaleFragment
      message={localeCommon.healthWarning.retailerPrepageHealthWarning}
      transformText="custom-pmi"
      insertIntlJSXPartOptions={{
        className: '',
        linkId: 'custom-info-message-link',
        linkRender: text => (
          <span
            aria-hidden
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open('https://ec.europa.eu/consumers/odr/', '_blank')}
          >
            <p className="no-margin d-inline">{text}</p>{' '}
          </span>
        ),
      }}
    />
  );

  return (
    <div className="health-warning-prepage">
      <div className="health-warning-prepage-header" />

      <div className="health-warning-prepage-body">
        <LanguageSwitcherComponent
          className="health-warning-language-switcher-wrapper"
          supportedLanguages={[...supportedLanguages].reverse()}
        />

        <div
          className={`health-warning ${isMobileResolution ? 'font-ta-v2-global-regulatory-text-medium' : 'font-ta-v2-custom-large-paragraph'}`}
        >
          {warning}
        </div>

        <button
          className={`agree-button ${isMobileResolution ? 'font-ta-v2-custom-small-header-bold' : 'font-ta-v2-custom-large-paragraph-bold'}`}
          style={{ backgroundColor: currentPrimaryColor }}
          type="button"
          onClick={handleClose}
        >
          <LocaleFragment message={localeCommon.healthWarning.retailerPrepageAgreeBtnText} />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  supportedLanguages: selectRetailerLanguages(state),
});

export default connect(mapStateToProps, null)(HealthWarningPrePage);
