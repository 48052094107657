import { useEffect, useState } from 'react';

import { actionGetCharacters } from '@lib/core/characters/slices';
import { actionGetEssenceGroups } from '@lib/core/essences/slices/groups';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionGetAllProducts } from '@lib/core/products/slices/products';
import { useQuizView, useUserQuiz } from '@lib/core/quizzes/hooks';
import { actionGetQuizzes } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { actionGetClientIp } from '@lib/core/service/slices/technical/clientIp';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const DataProvider = ({ children }) => {
  const dispatch = useTypedDispatch();

  const { locale } = useApp();
  const { isProductsLoading, productsList } = useProducts();
  const { userQuizType } = useUserQuiz();
  const { quizViewUserPath } = useQuizView();
  const { retailerSlug } = useRetailer();

  const [isQuizLoaded, setIsQuizLoaded] = useState(false);

  useEffect(() => {
    dispatch(actionGetClientIp());
  }, []);

  /**
   * * Flag to ensure fast initial load of the quiz without fetching products
   */
  useEffectSkipFirst(() => {
    // Fetch products after the quiz is available to avoid blocking the UI during initial quiz container loads.
    if (userQuizType) setIsQuizLoaded(true);
  }, [userQuizType]);

  /**
   * * Prefetch all products
   */
  useEffect(() => {
    if (Object.keys(quizViewUserPath).length === 2 && !isProductsLoading && !productsList.length) {
      dispatch(actionGetAllProducts());
    }
  }, [quizViewUserPath]);

  useEffect(() => {
    if (!isProductsLoading && isQuizLoaded) {
      dispatch(actionGetCharacters());
      dispatch(actionGetEssenceGroups());
    }
  }, [locale, isQuizLoaded]);

  useEffect(() => {
    if (retailerSlug.length) {
      dispatch(actionGetQuizzes());
    }
  }, [retailerSlug]);

  return children;
};
