import { IntlShape } from 'react-intl';

import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';

import { TProductInstance } from '@lib/core/products/types';
import { selectIsRemoteAccessValid, selectRemoteAccessKey } from '@lib/core/retailers/selectors/remoteAccess';
import {
  selectAppliedAddOns,
  selectIsRetailerLocationStoreTypeEcommerce,
  selectRetailerLocationProductCategories,
  selectRetailerLocationStoreType,
} from '@lib/core/retailers/selectors/retailerLocation';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { setProductCategory } from '@lib/core/service/slices';
import { store } from '@lib/core/service/store';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { selectCommsHostLeviaRedirectUrl, selectCommsHostRedirectUrl } from '@lib/tools/comms/selectors';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import RouteUtils from '@lib/tools/routes';
import { VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ERROR_TA_INVALID_ECOMMERCE_PRODUCTS_LINK } from '@components/pmi/src/utils/consts';

export const calculateAge = (inputYear: number, inputMonth: number): number => {
  const birthday = new Date(inputYear, inputMonth).getTime();
  return Math.floor((Date.now() - birthday) / 31557600000);
};

export function addNewLines(maxSymbols: number, incomingText: string): string {
  const textArray = incomingText?.split(' ');

  let textWithHyphen = '';
  for (let i = 0; i < textArray?.length; i += 1) {
    if (textArray?.[i].length > maxSymbols) {
      textArray[i] = `${textArray[i].slice(0, maxSymbols)}-\n${textArray[i].slice(maxSymbols)}`;
      textWithHyphen = textArray.join(' ');
    } else {
      textWithHyphen = textArray.join(' ');
    }
  }
  return textWithHyphen;
}

// ? Wrap injectIntl on this method instead
export const getTechnicalCaptionText = (intl, number) => {
  let currentText;

  switch (number) {
    case 1:
      currentText = intl.formatMessage(localeV2.result.technicalCaptionText1);
      break;
    case 2:
      currentText = intl.formatMessage(localeV2.result.technicalCaptionText2);
      break;
    default:
  }

  return currentText;
};

export function defineLongWord(incomingText: string): boolean {
  const textArray = incomingText?.split(' ');

  let isLongWord = false;
  for (let i = 0; i < textArray?.length; i += 1) {
    if (textArray?.[i].length > 10) {
      isLongWord = true;
      break;
    }
  }
  return isLongWord;
}

export const handleEcommerceSeeAllFlavours = (isLevia = false) => {
  const state = store.getState();

  const hostRedirectUrl = selectCommsHostRedirectUrl(state);
  const hostLeviaRedirectUrl = selectCommsHostLeviaRedirectUrl(state);

  if (isLevia && hostLeviaRedirectUrl) {
    trackCallToAction(GA_EVENT_CALL_TO_ACTION.SEE_ALL_FLAVOURS);

    window.top.location.href = hostLeviaRedirectUrl;
  } else if (hostRedirectUrl) {
    trackCallToAction(GA_EVENT_CALL_TO_ACTION.SEE_ALL_FLAVOURS);

    window.top.location.href = hostRedirectUrl;
  } else {
    console.warn(ERROR_TA_INVALID_ECOMMERCE_PRODUCTS_LINK);
  }
};

export const isMediaVideo = (mediaUrl: string) => mediaUrl.endsWith('.mp4');

export const checkCurrentPrimaryColor = () => {
  const productCategory = selectServiceProductCategory(store.getState());
  let primaryColor = '#00d1d2';

  if (productCategory === VEEV) {
    primaryColor = '#965ec7';
  }

  return primaryColor;
};

/**
 * * Navigates to the starting page based on `storeType` and applied `addOns`.
 * * guided-trial for 1 product - Quiz page
 * * ecommerce - Quiz page
 */
export const navigateHome = dispatch => {
  const state = store.getState();
  const storeType = selectRetailerLocationStoreType(state);
  const productCategories = selectRetailerLocationProductCategories(state);
  const remoteAccessKey = selectRemoteAccessKey(state);
  const { isEnableProductBaseLoopAddon } = selectAppliedAddOns(state);

  trackCallToAction(GA_EVENT_CALL_TO_ACTION.START_OVER);

  /**
   * ? Introduce CSC
   */
  switch (storeType) {
    case RetailerLocationStoreType.main:
      const showProductCategorySelector = productCategories.length > 1 && !isEnableProductBaseLoopAddon;
      if (showProductCategorySelector) dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));

      // Remote GT or Open Integration
      if (remoteAccessKey.length) history.push(RouteUtils.generateRemoteAccessLink());
      else history.push(prependBasename(PAGES.ta.landing));

      break;
    case RetailerLocationStoreType.ecommerce:
      history.push(prependBasename(PAGES.ta.quiz.local));
      break;
    default:
      break;
  }
};

/**
 * @returns a boolean true to hide bundles, bestMatch, and result pages for Wyng v3 inside an iframe.
 */
export const isWyngEcommerce = (): boolean => {
  const state = store.getState();
  const { isWyngEcommerceAddon } = selectAppliedAddOns(state);
  const isRetailerLocationStoreTypeEcommerce = selectIsRetailerLocationStoreTypeEcommerce(state);

  return isRetailerLocationStoreTypeEcommerce && isAppInIframe && isWyngEcommerceAddon;
};

export const isOpenIntegration = (): boolean => {
  const state = store.getState();
  const isRemoteAccessValid = selectIsRemoteAccessValid(state);
  return isRemoteAccessValid && isAppInIframe;
};

interface ExpirationPeriodTypes {
  quantity: number;
  type: string;
}

export const getExpirationPeriod = (value: number): ExpirationPeriodTypes => {
  switch (value) {
    case 1:
      return {
        quantity: 24,
        type: 'hours',
      };
    case 2:
      return {
        quantity: 48,
        type: 'hours',
      };
    case 3:
      return {
        quantity: 1,
        type: 'week',
      };
    case 4:
      return {
        quantity: 2,
        type: 'week',
      };
    case 5:
      return {
        quantity: 1,
        type: 'month',
      };
    case 6:
      return {
        quantity: 2,
        type: 'month',
      };
    case 7:
      return {
        quantity: 1,
        type: 'year',
      };
    default:
      return {
        quantity: 24,
        type: 'hours',
      };
  }
};

export const getExpirationLabel = (value: number, intl: IntlShape): string => {
  switch (value) {
    case 1:
      return `24 ${intl.formatMessage(localeCommon.accessKeyGenerator.hours)}`;
    case 2:
      return `48 ${intl.formatMessage(localeCommon.accessKeyGenerator.hours)}`;
    case 3:
      return `1 ${intl.formatMessage(localeCommon.accessKeyGenerator.week)}`;
    case 4:
      return `2 ${intl.formatMessage(localeCommon.accessKeyGenerator.weeks)}`;
    case 5:
      return `1 ${intl.formatMessage(localeCommon.accessKeyGenerator.month)}`;
    case 6:
      return `2 ${intl.formatMessage(localeCommon.accessKeyGenerator.months)}`;
    case 7:
      return `1 ${intl.formatMessage(localeCommon.accessKeyGenerator.year)}`;
    default:
      return `24 ${intl.formatMessage(localeCommon.accessKeyGenerator.hours)}`;
  }
};

export const getTobaccoText = (gprl: TProductInstance) => {
  const { product_type: type, is_pearl: isPearl } = gprl.product.attributes;
  const isPearlType = isPearl === 'true';
  const isLeviaType = type === 'levia';
  let tobaccoText = localeV2.common.tobaccoExperience;
  if (isPearlType) {
    tobaccoText = localeV2.common.tobaccoExperiencePearl;
  }
  if (isLeviaType) {
    tobaccoText = localeV2.common.tobaccoExperienceLevia;
  }
  return tobaccoText;
};
