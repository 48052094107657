import cn from 'classnames';
import { FC, useLayoutEffect, useState } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { showProductWithCoolingEssence, sortProductsByPortfolioOrder } from '@lib/tools/shared/pmi/products';
import {
  isGprlNotHidden,
  isProductLevia,
  isProductSentia,
  isProductTagNicotineLevel,
} from '@lib/tools/shared/pmi/products/filters';

import CTAButtonClose from '@components/pmi/src/atoms/CTA/ButtonClose';
import CTANavigator from '@components/pmi/src/atoms/CTA/Navigator';
import PortfolioCard from '@components/pmi/src/organisms/Products/PortfolioCard/PortfolioCard';
import MentholDisclaimer from '@components/pmi/src/organisms/Results/MentholDisclaimer/MentholDisclaimer';
import ResultLongDisclaimer from '@components/pmi/src/organisms/Results/ResultLongDisclaimer/ResultLongDisclaimer';

export interface Props {
  gprls: TProductInstance[];
  isOpen: boolean;
  onClose: () => void;
  openTereaPortfolio: () => void;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  selectedProductCategory: string;
  isVeev?: boolean;
  isMentholDisclaimerAddon: boolean;
  isResultLongDisclaimerAddon: boolean;
  isSentiaEnabled?: boolean;
  isRestrictedRecommendationAddon: boolean;
}

const PortfolioSection: FC<Props> = ({
  gprls,
  isOpen,
  onClose,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  selectedProductCategory,
  isVeev,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isMentholDisclaimerAddon,
  isResultLongDisclaimerAddon,
  isSentiaEnabled,
  openTereaPortfolio,
  isRestrictedRecommendationAddon,
}) => {
  useLayoutEffect(() => {
    if (isOpen) {
      document.body.classList.add('ta-v2-portfolio-modal-open');
    } else {
      document.body.classList.remove('ta-v2-portfolio-modal-open');
    }
  }, [isOpen]);

  const nonDimensionsTAProductsList = sortProductsByPortfolioOrder(
    gprls.filter(isGprlNotHidden).filter(gprl => !isProductLevia(gprl)),
    false,
  );
  const dimensionsTAProductsList = sortProductsByPortfolioOrder(
    gprls.filter(isGprlNotHidden).filter(gprl => !isProductLevia(gprl)),
    true,
  );
  const leviaTAProductsList = sortProductsByPortfolioOrder(gprls.filter(isGprlNotHidden).filter(isProductLevia), false);
  const isProductWithCoolingEssencePresent =
    gprls.some(gprl => showProductWithCoolingEssence(gprl)) && !isEssenceCoolingDisabledAddon;

  const isSentiaProduct = gprls.some(gprl => isProductSentia(gprl));
  const isLeviaProduct = gprls.some(gprl => isProductLevia(gprl));

  const [isPortfolioHidden, setIsPortfolioHidden] = useState(false);

  const viewTereaPortfolio = () => {
    setIsPortfolioHidden(true);

    const timeout = setTimeout(() => {
      openTereaPortfolio();
      setIsPortfolioHidden(false);
    }, 500);

    return () => clearTimeout(timeout);
  };

  return (
    <div
      className={cn('ta-v2-portfolio-section', {
        'ta-v2-portfolio-section-open': isOpen,
      })}
    >
      <div className="ta-v2-portfolio-section-blur-container">
        <div className="ta-v2-portfolio-section-container">
          <div className="ta-v2-portfolio-section-cards-block">
            <div className="ta-v2-portfolio-section-btn-close-container">
              <CTAButtonClose
                btnText={<LocaleFragment message={localeV2.portfolio.closeBtn} />}
                isDark={false}
                onBtnClick={onClose}
              />
            </div>
            {isLeviaProduct ? (
              <div className="ta-v2-portfolio-section-product-title font-ta-v2-title-6">
                <LocaleFragment message={localeV2.portfolio.tereaProductTitle} />
              </div>
            ) : null}

            <div
              className={cn('ta-v2-portfolio-section-card-container', {
                hidden: isPortfolioHidden,
              })}
            >
              {nonDimensionsTAProductsList.map((retailerProductLocation, i) => (
                <PortfolioCard
                  key={i}
                  isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                  isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                  isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                  isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                  isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                  isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                  isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                  isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                  isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                  isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                  isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                  isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                  isVeev={isVeev}
                  retailerProductLocation={retailerProductLocation}
                  selectedProductCategory={selectedProductCategory}
                  nicotineLevelData={retailerProductLocation.retailer_product_location_tags?.filter(
                    isProductTagNicotineLevel,
                  )}
                />
              ))}
            </div>
            <div
              className={cn('ta-v2-portfolio-section-card-container', {
                hidden: isPortfolioHidden,
              })}
            >
              {dimensionsTAProductsList.map((retailerProductLocation, i) => (
                <PortfolioCard
                  key={i}
                  isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                  isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                  isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                  isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                  isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                  isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                  isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                  isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                  isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                  isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                  isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                  isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                  isVeev={isVeev}
                  retailerProductLocation={retailerProductLocation}
                  selectedProductCategory={selectedProductCategory}
                  nicotineLevelData={retailerProductLocation.retailer_product_location_tags?.filter(
                    isProductTagNicotineLevel,
                  )}
                />
              ))}
            </div>
            {isLeviaProduct ? (
              <div className="ta-v2-portfolio-section-product-title font-ta-v2-title-6">
                <LocaleFragment message={localeV2.portfolio.leviaProductTitle} />
              </div>
            ) : null}
            <div
              className={cn('ta-v2-portfolio-section-card-container', {
                hidden: isPortfolioHidden,
              })}
            >
              {leviaTAProductsList.map((retailerProductLocation, i) => (
                <PortfolioCard
                  key={i}
                  isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                  isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                  isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                  isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                  isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                  isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                  isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                  isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                  isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                  isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                  isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                  isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                  isVeev={isVeev}
                  retailerProductLocation={retailerProductLocation}
                  selectedProductCategory={selectedProductCategory}
                  nicotineLevelData={retailerProductLocation.retailer_product_location_tags?.filter(
                    isProductTagNicotineLevel,
                  )}
                />
              ))}
            </div>
          </div>

          {isSentiaEnabled && isSentiaProduct && (
            <CTANavigator
              btnText={<LocaleFragment message={localeV2.portfolio.tereaPortfolio} />}
              className="margin-top-24px text-underlined"
              direction="right"
              isDark={false}
              onBtnClick={viewTereaPortfolio}
            />
          )}

          {isMentholDisclaimerAddon && isProductWithCoolingEssencePresent && <MentholDisclaimer isLight />}
          {isResultLongDisclaimerAddon && <ResultLongDisclaimer isLight />}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
