/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { addToEcommerceCart } from '@app/pmi/src/comms/utils';

import { TProductInstance } from '@lib/core/products/types';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { localeCrafted } from '@lib/tools/locale/source/pmi/crafted';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useEqualElementsHeightV2, useHeight } from '@lib/tools/views/hooks';
import { useTypedDispatch as useDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/scrollbar';

interface ProductCarouselProps {
  gprls: TProductInstance[];
  handleGoToResult: () => void;
  handleSendResultMetadata: () => void;
  setActiveCardIndex: Dispatch<SetStateAction<number>>;
  activeCardIndex: number;
}

interface ProductCarouselSlideProps {
  index: number;
  caption: string;
  image: string;
  name: string;
  currency: string | number;
  price: string;
}

function ProductCarouselCard({ caption, image, name, currency, price }: ProductCarouselSlideProps) {
  useEqualElementsHeightV2(['ta-botanicals-carousel-card-name', 'ta-botanicals-carousel-card-caption']);
  const currencySymbolValue = LocaleUtils.getCurrencySymbolFromCode(currency);

  return (
    <div className="ta-botanicals-carousel-card">
      <div className="ta-botanicals-carousel-card-img-cover">
        <img alt="CardImg" className="ta-botanicals-carousel-card-img" src={image} />
        <div className="ta-botanicals-carousel-card-img-overlay" />
      </div>
      <p className="ta-botanicals-carousel-card-name">{name}</p>
      <p className="ta-botanicals-carousel-card-caption">{caption}</p>
      <p className="ta-botanicals-carousel-card-price">
        {currencySymbolValue} {price}
      </p>
    </div>
  );
}

const ProductCarousel = ({
  gprls,
  handleGoToResult,
  handleSendResultMetadata,
  setActiveCardIndex,
  activeCardIndex,
}: ProductCarouselProps) => {
  const dispatch = useDispatch();
  const carouselArr = gprls;
  const [addToCartProducts, setAddToCartProducts] = useState<string[]>([]);

  const [activeCardRef, activeCardHeight] = useHeight<HTMLDivElement>();

  const handleProductCardClick = clickedIndex => {
    setActiveCardIndex(clickedIndex);
  };

  useEffect(() => {
    const payload = {
      metadata: {
        add_to_cart_products: addToCartProducts,
      },
    };

    if (addToCartProducts.length) {
      dispatch(actionPatchUserQuizMetadata(payload));
    }
  }, [addToCartProducts]);

  const handleSendMetadataCartProducts = () => {
    if (!addToCartProducts.includes(gprls[activeCardIndex].product.identifier)) {
      setAddToCartProducts(current => [...current, gprls[activeCardIndex].product.identifier]);
    }
  };

  return (
    <>
      <div className="ta-botanicals-product-carousel" style={{ height: activeCardHeight }}>
        {carouselArr.map((gprl, i) => {
          const { currency, price, image } = gprl;
          const { name, caption } = gprl.product;

          /**
           * @todo fix styles
           */
          return (
            <div
              key={i}
              ref={i === activeCardIndex ? activeCardRef : null}
              className={cn('product-card', {
                active: i === activeCardIndex,
                'inactive-left':
                  (activeCardIndex === 0 && i === 2) ||
                  (activeCardIndex === 1 && i === 0) ||
                  (activeCardIndex === 2 && i === 1),
                'inactive-right':
                  (activeCardIndex === 0 && i === 1) ||
                  (activeCardIndex === 1 && i === 2) ||
                  (activeCardIndex === 2 && i === 0),
              })}
              onClick={() => {
                handleProductCardClick(i);
                if (i === activeCardIndex) {
                  handleGoToResult();
                  handleSendResultMetadata();
                }
              }}
            >
              <ProductCarouselCard
                caption={caption}
                currency={currency}
                image={image}
                index={i}
                name={name}
                price={price}
              />
            </div>
          );
        })}
      </div>
      <button
        className="ta-botanicals-carousel-card-button"
        onClick={() => {
          handleSendMetadataCartProducts();
          addToEcommerceCart(gprls[activeCardIndex].ecommerce_id, 1);
        }}
      >
        <LocaleFragment message={localeCrafted.quiz.ctaAddToCart} />
      </button>
    </>
  );
};

export default ProductCarousel;
