import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  GA_EVENT_CALL_TO_ACTION,
  GA_EVENT_INTERMEDIATE_PAGE,
  trackCallToAction,
  trackIntermediateRecommendation,
} from '@app/pmi/src/comms/events';
import { broadcastDomHeightToHost, broadcastUserQuizResultToHost } from '@app/pmi/src/comms/utils';

import { useProducts } from '@lib/core/products/hooks/products';
import { useProductSets } from '@lib/core/products/hooks/productSets';
import { actionGetAllProducts } from '@lib/core/products/slices/products';
import {
  actionGetProductSetProducts,
  actionGetProductSets,
  actionResetProductSets,
} from '@lib/core/products/slices/productSets';
import { useQuiz, useQuizView } from '@lib/core/quizzes/hooks';
import { selectQuizViewPausedRoute } from '@lib/core/quizzes/selectors';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { actionTraverseQuizViewData } from '@lib/core/quizzes/slices/quizView';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { POST_MESSAGE_PRODUCT_SETS } from '@lib/tools/comms/consts';
import { isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import {
  isQuizAnswerTagContextBestMatch,
  isQuizAnswerTagContextCharacterMatch,
  isQuizAnswerTagContextMood,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { HEETS } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import BundlePageComponentV2 from '@components/pmi/src/templates/BundlesPage/BundlesPage';
import LoadingComponentV2 from '@components/pmi/src/templates/LoadingPageV2/LoadingPageV2';
import { checkCurrentPrimaryColor, isWyngEcommerce } from '@components/pmi/src/utils';

const BundlesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productCategory } = useApp();
  const {
    isAromaNoteSecondaryAddon,
    isAddToCartAddon,
    isAromaNotesDisabledAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isEnableBuyEcommerceAddon,
    isRestrictedBundlesPageAddon,
    isRestrictedProductCardsAddon,
    isTereaHideEssencesAddon,
    isTobaccoExperienceHiddenAddon,
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isHealthWarningFooterAddon,
    isMentholDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
    isResultLongDisclaimerAddon,
    isEnableLeviaBannerAddon,
    isRestrictedRecommendationAddon,
  } = useAddons();
  const { retailerTags } = useRetailer();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const { quizViewAnswerTags } = useQuizView();
  const { productSetIds, productSetProducts, isProductSetsProductListIsFetching } = useProductSets();
  const { productsList, isProductsLoading } = useProducts();

  const URL_PARAM_QA_CHARACTER = 'character';
  const URL_PARAM_QA_MOOD = 'mood';
  const URL_PARAM_QA_PRODUCT = 'product';

  const isShowHealthWarningFooter =
    (!isRetailerLocationStoreTypeEcommerce || (isRetailerLocationStoreTypeEcommerce && isHealthWarningFooterAddon)) &&
    !isHealthWarningFooterToIsraelAddon;

  // QA can set params like ?mood=left&character=IC01S01 on the bundle page to check the ProductSets directly.
  const urlParamQAMood = new URL(window.location.href).searchParams.get(URL_PARAM_QA_MOOD);
  const urlParamQACharacter = new URL(window.location.href).searchParams.get(URL_PARAM_QA_CHARACTER);
  const urlParamQAProduct = new URL(window.location.href).searchParams.get(URL_PARAM_QA_PRODUCT);

  const quizRoute = useSelector(selectQuizViewPausedRoute);

  const [bundleProductFeedbackList, updateBundleProductFeedbackList] = useState<Record<string, string>[]>([]);

  const currentPrimaryColor = checkCurrentPrimaryColor();

  const handleGoToNextQuestion = () => {
    trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.GET_MORE_SUGGESTION}`);
    navigate(prependBasename(quizRoute));
  };

  const mood = quizViewAnswerTags.find(isQuizAnswerTagContextMood)?.name;
  const character = quizViewAnswerTags.find(isQuizAnswerTagContextCharacterMatch)?.name;
  const bestMatchSlug = quizViewAnswerTags.find(isQuizAnswerTagContextBestMatch)?.name;

  const isSentiaAvailable = productsList.some(isProductSentia);
  const sentiaBestMatch = productsList.find(gprl => gprl.product.slug === bestMatchSlug && isProductSentia(gprl));
  const tereaProducts = productsList
    .filter(gprl => gprl.product.product_category === productCategory)
    .filter(gprl => !isProductSentia(gprl));

  useLayoutEffect(() => {
    broadcastDomHeightToHost();
  }, [productSetProducts, isProductSetsProductListIsFetching]);

  /**
   * * Get `ProductSets` when answerTags with contexts `mood` and `character` are encountered
   */
  useEffect(() => {
    if (character && mood) {
      dispatch(actionGetProductSets({ characterId: character, mood }));
    }
    if (!Object.keys(quizViewAnswerTags).length) {
      if (!urlParamQACharacter || !urlParamQAMood || !urlParamQAProduct) {
        navigate(prependBasename(PAGES.ta.landing));
      }
    }
  }, [character, mood]);

  useEffect(() => {
    if (!productsList.length && !isProductsLoading) {
      dispatch(actionGetAllProducts());
    }
  }, [productsList, isProductsLoading]);
  /**
   * * Fetch ProductSetProducts
   */
  useEffect(() => {
    if (productSetIds.length && productsList.length && !isProductSetsProductListIsFetching) {
      dispatch(actionGetProductSetProducts({ bestMatchSlug, productSetIds }));
    }
  }, [productSetIds, productsList, bestMatchSlug]);

  useEffect(() => {
    // Insert feedback of the products in the bundles into the userQuiz metadata
    // Example:
    // {
    // 	"metadata": {
    // 		"product_sets_feedback": [{
    // 			"IH001": false,
    // 			"IH002": true,
    // 			"product_set_id": "PS0001"
    // 		}, {
    // 			"IH005": false,
    // 			"IH006": true,
    // 			"product_set_id": "PS0003"
    // 		}]
    // 	}
    // }
    if (bundleProductFeedbackList.length) {
      const payload = { metadata: { product_sets_feedback: bundleProductFeedbackList } };
      dispatch(actionPatchUserQuizMetadata(payload));
    }
  }, [bundleProductFeedbackList]);

  useEffect(() => {
    // Insert bundle info into the userQuiz metadata
    // Example:
    // {
    //   metadata: {
    //     product_sets_displayed: [
    //       {
    //         product_set_id: 'PS0005',
    //         product_set_rank: 0,
    //         products: {
    //           IH002: {
    //             retrieved_rank: 2,
    //             sorted_rank: 3,
    //           },
    //           IH003: {
    //             retrieved_rank: -100,
    //             sorted_rank: 1,
    //           },
    //         },
    //       },
    //       {
    //         product_set_id: 'PS0010',
    //         product_set_rank: 1,
    //         products: {
    //           IH004: {
    //             retrieved_rank: 1,
    //             sorted_rank: 2,
    //           },
    //           IH023: {
    //             retrieved_rank: -100,
    //             sorted_rank: 2,
    //           },
    //         },
    //       },
    //     ],
    //   },
    // }

    if (productSetProducts.length) {
      const payload = { metadata: { is_qa: true, product_sets_displayed: [] } };

      productSetProducts.forEach((productSetProduct, index) => {
        const preparedProductsObject = {};
        const productsInProductSet = productSetProduct.products;

        productsInProductSet.forEach((gprl, i) => {
          preparedProductsObject[gprl.product.identifier] = {
            retrieved_rank: gprl.product.rank,
            sorted_rank: i + 1,
          };
        });

        payload.metadata.product_sets_displayed.push({
          product_set_id: productSetProduct.product_set_id,
          product_set_rank: index,
          products: preparedProductsObject,
        });
      });

      if (!urlParamQACharacter || !urlParamQAMood || !urlParamQAProduct) {
        delete payload.metadata.is_qa;
      }

      dispatch(actionPatchUserQuizMetadata(payload));
      trackIntermediateRecommendation(GA_EVENT_INTERMEDIATE_PAGE.BUNDLES, [
        productSetProducts[0].products?.[0]?.product?.name,
        productSetProducts[0].products?.[1]?.product?.name,
        productSetProducts[1].products?.[0]?.product?.name,
        productSetProducts[1].products?.[1]?.product?.name,
      ]);

      if (isWyngEcommerce()) {
        broadcastUserQuizResultToHost(POST_MESSAGE_PRODUCT_SETS, productSetProducts);
        handleGoToNextQuestion();
      }
    }
  }, [productSetProducts]);

  const { remoteAccessKey } = useRemoteAccess();

  const { quizId } = useQuiz();

  useEffect(() => {
    if (!quizId) {
      navigate(prependBasename(remoteAccessKey ? `${PAGES.ta.quiz.remote}` : `${PAGES.ta.quiz.local}`));
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
    dispatch(actionTraverseQuizViewData({ forceBacktrack: true, steps: -1 }));
    dispatch(actionResetProductSets());
  };

  const handleBundleProductFeedback = (data: Record<string, string>) => {
    if (!bundleProductFeedbackList.length) {
      updateBundleProductFeedbackList([data]);
    } else {
      let isExistingBundleUpdated = false;
      bundleProductFeedbackList.forEach((bundleProductFeedback, index) => {
        if (bundleProductFeedback.product_set_id === data.product_set_id) {
          bundleProductFeedbackList[index] = { ...bundleProductFeedback, ...data };
          updateBundleProductFeedbackList([...bundleProductFeedbackList]);
          isExistingBundleUpdated = true;
        }
      });

      if (!isExistingBundleUpdated) {
        updateBundleProductFeedbackList([...bundleProductFeedbackList, { ...data }]);
      }
    }
  };

  return productSetProducts.length && !isProductSetsProductListIsFetching && !isWyngEcommerce() ? (
    <BundlePageComponentV2
      currentPrimaryColor={currentPrimaryColor}
      getMoreSuggestion={handleGoToNextQuestion}
      goBack={handleGoBack}
      handleBundleProductFeedback={handleBundleProductFeedback}
      isAddToCartAddon={isAddToCartAddon}
      isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
      isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
      isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
      isEnableLeviaBannerAddon={isEnableLeviaBannerAddon}
      isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
      isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
      isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
      isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
      isMentholDisclaimerAddon={isMentholDisclaimerAddon}
      isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
      isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
      isRestrictedBundlesPageAddon={isRestrictedBundlesPageAddon}
      isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
      isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
      isResultLongDisclaimerAddon={isResultLongDisclaimerAddon}
      isSentiaEnabled={isSentiaAvailable && !!sentiaBestMatch}
      isShowHealthWarningFooter={isShowHealthWarningFooter}
      isTereaHideEssencesAddon={isTereaHideEssencesAddon}
      isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
      maxQuantity={retailerTags?.ecommerceQuantityRange?.[1]}
      minQuantity={retailerTags?.ecommerceQuantityRange?.[0]}
      productSetList={productSetProducts}
      selectedProductCategory={productCategory}
      gprls={
        isSentiaAvailable && !sentiaBestMatch
          ? tereaProducts
          : productsList.filter(gprl => gprl.product.product_category === productCategory)
      }
    />
  ) : (
    <LoadingComponentV2 isHeetsBackgroundEnabled={productCategory === HEETS} />
  );
};

export default BundlesContainer;
