import { defineMessages } from '@formatjs/intl';

export const localeCrafted = {
  landing: defineMessages({
    ctaStart: {
      defaultMessage: 'Start',
      id: 'crafted.landing.cta.start',
    },
    help: {
      defaultMessage: 'Click ‘Start’ to find out which TEREA Crafted flavour suits you the best!',
      id: 'crafted.landing.text.instructions',
    },
    subtitle: {
      defaultMessage:
        'Now our blenders have crafted a new range of tobacco flavours dedicated to presenting the **true essence** of natural botanicals in an exciting way.',
      id: 'crafted.landing.text.subtitle',
    },
    title: {
      defaultMessage:
        'Everything **truly authentic** begins in nature. The natural world of botanicals has given us **many unique** tastes that have elevated culinary traditions all around the world.',
      id: 'crafted.landing.text.title',
    },
  }),
  quiz: defineMessages({
    ctaAddToCart: {
      defaultMessage: 'Add to cart',
      id: 'crafted.result.cta.add_to_cart',
    },
    ctaFlavour: {
      defaultMessage: 'Learn about your selected flavour',
      id: 'crafted.result.cta.flavour',
    },
    instructions: {
      defaultMessage: 'Click and drag to turn each wheel',
      id: 'crafted.quiz.text.instructions',
    },
    resultTitle: {
      defaultMessage: 'This TEREA Crafted variant could be ideal for your selected flavour combination.',
      id: 'crafted.result.text.title',
    },
    rotate: {
      defaultMessage: 'Rotate',
      id: 'crafted.quiz.text.rotate',
    },
    submit: {
      defaultMessage: 'Submit',
      id: 'crafted.quiz.cta.submit',
    },
    title: {
      defaultMessage: 'Select a combination below with the three wheels to find a flavour that suits you the best.',
      id: 'crafted.quiz.text.title',
    },
    titleSelect: {
      defaultMessage: 'Are you satisfied with your combination?',
      id: 'crafted.quiz.text.confirm_selection',
    },
  }),
};
