import cn from 'classnames';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import slateBackgroundImage from '@app/pmi/src/assets/media/V2/v2_slate_background.png';
import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  isCoolingEssencePresent,
  reOrderEssencesForIQOS3Designs,
  reOrderEssencesForIQOS4Designs,
} from '@lib/tools/shared/pmi/essences';
import { HEETS_EU, TEREA_EU } from '@lib/tools/shared/pmi/essences/consts';
import { redirectToProductPage, retailerProductLocationTags } from '@lib/tools/shared/pmi/products';
import { HEETS, SENTIA, TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import AddToCart from '@components/pmi/src/organisms/Products/AddToCart/AddToCart';
import AromaNote from '@components/pmi/src/organisms/Products/AromaNote/AromaNote';
import EssencesBars from '@components/pmi/src/organisms/Products/EssencesBars/BarEssences';
import EssencesEU from '@components/pmi/src/organisms/Products/EssencesEU/EssencesEU';
import Feedback from '@components/pmi/src/organisms/Products/Feedback/Feedback';
import { getTobaccoText } from '@components/pmi/src/utils';

interface Props {
  bundlesProduct: TProductInstance;
  isBtnDisable?: boolean;
  productSetID?: string;
  handleBundleProductFeedback: (data: Record<string, string>) => void;
  selectedProductCategory: string;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  orientation: 'left' | 'right';
  minQuantity: number;
  maxQuantity: number;
  isVeev?: boolean;
  isMentholDisclaimerAddon: boolean;
  isRestrictedRecommendationAddon: boolean;
}

const BundlesProductCard: FC<Props> = ({
  bundlesProduct,
  selectedProductCategory,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isAddToCartAddon,
  isEnableBuyEcommerceAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  orientation,
  isBtnDisable = false,
  handleBundleProductFeedback,
  productSetID,
  maxQuantity,
  minQuantity,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isVeev,
  isMentholDisclaimerAddon,
  isRestrictedRecommendationAddon,
}) => {
  useEqualElementsHeightV2([
    'ta-v2-bundles-product-name',
    'ta-v2-bundles-product-caption',
    'ta-v2-bundles-product-description',
    'ta-v2-bundles-product-essences-block',
    'ta-v2-bundles-product-essences-bar',
    'ta-v2-bundles-product-tobacco',
    'ta-v2-bundles-product-footer-block',
    'ta-v2-bundles-description',
  ]);
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const { isLayoutRightToLeft } = useApp();
  const { image, currency, price, url, description: bundleDescription } = bundlesProduct;
  const {
    essences,
    name,
    caption,
    description: productDescription,
    attributes,
    aromas,
    identifier,
    product_category: productCategory,
  } = bundlesProduct.product;
  const {
    background_image_v2: backgroundImage,
    restricted_background_image_v2: restrictedBackgroundImage,
    color_primary: colorPrimary,
    color,
    is_pearl: isPearl,
    product_type: type,
  } = attributes;
  const isPearlType = isPearl === 'true';

  const { isEuClassicTobaccoEssencesTag } = retailerProductLocationTags(bundlesProduct);
  const isCooling = isMentholDisclaimerAddon && isCoolingEssencePresent(essences);

  let currentEssences = essences;
  let currentColor = '';
  let currentBackground = '';
  if (selectedProductCategory === TEREA) {
    currentColor = colorPrimary;
    if (isRestrictedProductCardsAddon) {
      currentBackground = '#34303d';
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    }
    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === TEREA_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS4Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }
  if (selectedProductCategory === HEETS) {
    currentColor = color;
    if (isRestrictedProductCardsAddon) {
      currentBackground = `url(${slateBackgroundImage}) left top/contain repeat`;
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    }
    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === HEETS_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS3Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }

  return (
    <div
      style={{ background: currentBackground }}
      className={cn('ta-v2-bundles-product', {
        'left-card': orientation === 'left',
        'right-card': orientation === 'right',
        'rtl-orientation': isLayoutRightToLeft,
      })}
    >
      <div className="ta-v2-bundles-product-content-block">
        <div className="ta-v2-bundles-product-content-block-image-wrapper">
          <img alt="bundles product img" className="ta-v2-bundles-product-content-block-image" src={image} />
        </div>

        <div className="font-ta-v2-custom-small-header-bold text-uppercase">
          {productCategory === TEREA ? type : productCategory}
        </div>

        <div className="ta-v2-bundles-product-name font-ta-v2-custom-large-paragraph-bold">{name}</div>

        {!isProductShortDescriptionHiddenAddon && (
          <div className="ta-v2-bundles-product-caption font-ta-v2-global-body-large font-weight-bold">
            {`${caption}${isCooling ? '*' : ''}`}
          </div>
        )}

        {!isTobaccoExperienceHiddenAddon ? (
          <div className="ta-v2-bundles-product-tobacco font-ta-v2-global-body-large font-weight-bold">
            <LocaleFragment message={getTobaccoText(bundlesProduct)} />
          </div>
        ) : null}

        {!isProductLongDescriptionHiddenAddon && (
          <div className="ta-v2-bundles-product-description font-ta-v2-global-regulatory-text-medium margin-top-8px">
            {productDescription}
          </div>
        )}

        <div
          className="ta-v2-bundles-product-essences-block"
          style={{ backgroundColor: isTereaHideEssencesAddon && !isEuClassicTobaccoEssencesTag && 'transparent' }}
        >
          {isEuClassicTobaccoEssencesTag ? (
            <EssencesEU
              isHorizontalOrientation
              color={currentColor}
              isDimensionSmall={isMobileResolution}
              name={currentEssences[0]?.name}
              score={+currentEssences[0]?.score}
            />
          ) : (
            <div className="ta-v2-bundles-product-essences-bar w-100">
              {isTereaHideEssencesAddon ? null : <EssencesBars color={currentColor} essences={currentEssences} />}
            </div>
          )}
          {isAromaNotesDisabledAddon || isEuClassicTobaccoEssencesTag || type === SENTIA ? null : (
            <AromaNote
              isHorizontalOrientation
              description={aromas?.[0]?.description}
              isDimensionSmall={isMobileResolution}
              isPearlType={isPearlType}
              image={
                isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                  ? aromas?.[0]?.secondary_image
                  : aromas?.[0]?.image
              }
            />
          )}
        </div>
        <div className="ta-v2-bundles-description">
          {bundleDescription && (
            <div className="font-ta-v2-global-regulatory-text-medium margin-top-16px">{bundleDescription}</div>
          )}
        </div>
      </div>
      <div
        className={cn('ta-v2-bundles-product-footer-block', {
          'left-card': orientation === 'left',
          'right-card': orientation === 'right',
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        {isEnableBuyEcommerceAddon ? (
          <CTAButton
            isDark
            btnText={<LocaleFragment message={localeV2.common.buyEcommerce} />}
            className="ta-v2-bundles-product-footer-btn"
            isDisabled={isBtnDisable}
            onBtnClick={() => {
              trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.BUY}`);
              redirectToProductPage(url);
            }}
          />
        ) : (
          <>
            {isAddToCartAddon ? (
              <AddToCart
                className="ta-v2-bundles-product-add-to-cart"
                currency={currency}
                ecommerceID={bundlesProduct.ecommerce_id}
                isBtnDisable={isBtnDisable}
                isVeev={isVeev}
                maxQuantity={maxQuantity}
                minQuantity={minQuantity}
                optionID={bundlesProduct.tags?.option_id}
                price={price}
                variantID={bundlesProduct.tags?.variant_id}
              />
            ) : (
              <Feedback
                className="ta-v2-feedback-bundles-product-card"
                handleBundleProductFeedback={handleBundleProductFeedback}
                identifier={identifier}
                isBtnDisable={isBtnDisable}
                productSetID={productSetID}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BundlesProductCard;
